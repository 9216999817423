<script>
export default {
  name: "EmptyHistory",

  props: {},

  computed: {},
};
</script>

<template>
  <section class="empty-history">
    <span class="empty-history__icon icon-file-fill" />

    <h3 class="empty-history__title">Пока ничего нет</h3>

    <p class="empty-history__text">
      Здесь будет отображаться информация о ваших получаемых и предоставляемых
      услугах
    </p>
  </section>
</template>

<style lang="scss" scoped>
.empty-history {
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    width: 96px;
    height: 96px;
    background: $light-sixth;
    margin-bottom: $space-l;

    @media (max-width: 1023px) {
      width: 120px;
      height: 120px;
      margin-bottom: $space-m;
    }
  }

  &__title {
    @include title-3;

    margin-bottom: $space-s;
    color: $dark-primary;
  }

  &__text {
    @include body-1;

    text-align: center;
    color: $dark-primary;
  }
}
</style>
