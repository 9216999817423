<script>
export default {
  name: "Tabs",
  components: {},

  model: {
    prop: "modelValue",
    event: "change",
  },

  props: {
    modelValue: { default: "" },

    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  watch: {},

  computed: {},

  methods: {
    handleClickTab(code) {
      this.$emit("change", code);
    },
  },
};
</script>

<template>
  <div class="tabs">
    <button
      v-for="item in items"
      :key="item.code"
      class="tabs__button"
      type="button"
      :class="{ tabs__button_active: modelValue === item.code }"
      @click="handleClickTab(item.code)"
    >
      {{ item.title }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  display: flex;
  gap: 0;

  &__button {
    padding: 8px 16px;
    @include text-2;
    color: $dark-primary;
    background: $light-primary;
    border: none;
    width: 50%;
    height: 40px;
    transition: all 0.3s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;

    &_active {
      background: $dark-primary !important;
      color: $light-primary !important;
    }

    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      border-radius: 0 8px 8px 0;
    }
  }
}
</style>
