<script>
import MainTitleBack from "@/components/helpers/MainTitleBack.vue";
import Tabs from "@/components/Tabs.vue";
import MainSelect from "@/components/helpers/MainSelect.vue";
import CardHistoryService from "@/components/transaction-support/CardHistoryService.vue";
import { mapActions, mapState } from "vuex";
import MainObserver from "@/components/helpers/MainObserver.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import { STATUS_OPERATIONS } from "@/package/const/service-tsp";
import EmptyHistory from "@/components/transaction-support/EmptyHistory.vue";

export default {
  name: "HistoryService",

  components: {
    MainLoader,
    MainObserver,
    MainSelect,
    Tabs,
    MainTitleBack,
    CardHistoryService,
    EmptyHistory,
  },

  data() {
    return {
      tabs: [
        { title: "Оказываемые", code: "provided" },
        { title: "Получаемые", code: "received" },
      ],
      selectedTab: "provided",
      selectedOption: { id: "any", name: "Все заявки" },
      optionsFilters: [
        { id: "any", name: "Все заявки" },
        { id: "new", name: STATUS_OPERATIONS.new.text },
        { id: "in_work", name: STATUS_OPERATIONS.in_work.text },
        { id: "canceled", name: STATUS_OPERATIONS.canceled.text },
        { id: "awaited_approve", name: STATUS_OPERATIONS.awaited_approve.text },
        { id: "awaited_cancel", name: STATUS_OPERATIONS.awaited_cancel.text },
        { id: "awaited_check", name: STATUS_OPERATIONS.awaited_check.text },
        { id: "finished", name: STATUS_OPERATIONS.finished.text },
      ],
      isEmptyProvided: false,
      isEmptyReceived: false,
      isShowTabs: false,
      isLoadingAllRequests: true,
    };
  },

  computed: {
    ...mapState({
      isLoadingHistoryProvided: (state) =>
        state.transactionSupport.isLoadingHistoryProvided,
      isLoadingHistoryReceived: (state) =>
        state.transactionSupport.isLoadingHistoryReceived,
      historyProvided: (state) => state.transactionSupport.historyProvided,
      historyReceived: (state) => state.transactionSupport.historyReceived,
    }),

    isShowLoader() {
      return (
        (this.selectedTab === "received" && this.isLoadingHistoryReceived) ||
        (this.selectedTab === "provided" && this.isLoadingHistoryProvided)
      );
    },

    showerItems() {
      if (this.selectedTab === "provided") {
        return this.historyProvided;
      }

      return this.historyReceived;
    },

    isShowEmptyBlock() {
      return (
        this.selectedOption.id === "any" &&
        !this.historyProvided.length &&
        !this.historyReceived.length &&
        !this.isLoadingHistoryReceived &&
        !this.isLoadingHistoryProvided
      );
    },
  },

  methods: {
    ...mapActions({
      getHistoryProvided: "transactionSupport/getHistoryProvided",
      getHistoryReceived: "transactionSupport/getHistoryReceived",
    }),

    interceptObserver(optionFilter = "") {
      if (this.selectedTab === "received") {
        if (!this.isLoadingHistoryReceived)
          this.getHistoryReceived(optionFilter);

        return;
      }

      if (this.selectedTab === "provided") {
        if (!this.isLoadingHistoryProvided)
          this.getHistoryProvided(optionFilter);
      }
    },
  },

  watch: {
    selectedTab: {
      handler(value) {
        this.$router.push({ query: { service: value }, replace: true });

        this.interceptObserver(this.selectedOption.id);
      },
    },

    selectedOption: {
      deep: true,
      handler(value) {
        this.interceptObserver(value.id);
      },
    },
  },

  mounted() {
    const service = this.$route.query?.service;
    const allQuery = ["provided", "received"];

    Promise.all([
      this.getHistoryProvided("any"),
      this.getHistoryReceived("any"),
    ])
      .then((res) => {
        if (this.historyProvided.length && this.historyReceived.length) {
          this.isShowTabs = true;
        }

        if (allQuery.includes(service)) {
          if (service === allQuery[0] && res[0]?.data?.length) {
            this.selectedTab = "provided";

            return;
          }

          if (service === allQuery[1] && res[1]?.data?.length) {
            this.selectedTab = "received";
          }
        } else {
          if (res[0]?.data?.length || res[2]?.data?.length) {
            this.selectedTab = allQuery[0];
            this.$router.push({
              query: { service: allQuery[0] },
              replace: true,
            });

            return;
          }

          this.selectedTab = allQuery[1];
          this.$router.push({ query: { service: allQuery[1] }, replace: true });
        }
      })
      .finally(() => {
        this.isLoadingAllRequests = false;
      });
  },
};
</script>

<template>
  <div class="history-service default-content-padding">
    <MainTitleBack
      href="TransactionSupportTsp"
      title="История услуг"
      size="l"
    />

    <div v-if="isShowEmptyBlock" class="history-service__empty-box">
      <EmptyHistory class="history-service__empty-block" />
    </div>

    <div v-else class="history-service__container">
      <Tabs
        v-if="isShowTabs && !isLoadingAllRequests"
        v-model="selectedTab"
        class="history-service__tabs"
        :items="tabs"
      />

      <MainSelect
        v-if="!isLoadingAllRequests"
        class="history-service__select"
        :options="optionsFilters"
        :selected-option.sync="selectedOption"
        style-type="background-white"
      />

      <section class="history-service__list-history">
        <template v-if="!isLoadingAllRequests">
          <CardHistoryService
            v-for="item in showerItems"
            :key="item.id"
            :data="item"
            :type-card="selectedTab"
          />
        </template>

        <span
          v-if="!showerItems.length && !isShowLoader"
          class="history-service__empty-list"
        >
          Услуги не найдены
        </span>

        <MainLoader v-if="isShowLoader" />

        <MainObserver @intersect="interceptObserver" />
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.history-service {
  padding: $space-xxl $space-xxl $space-xxxl;
  height: calc(100% - 64px);
  display: grid;
  grid-template-rows: max-content 1fr;

  @media (max-width: 1023px) {
    padding: 40px $space-l;
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 560px;
    width: 100%;

    @media (max-width: 1023px) {
      max-width: none;
    }
  }

  &__select::v-deep {
    .main-select {
      &__items {
        max-width: 100% !important;
      }

      &_background-white {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &__tabs {
    max-width: 320px;
    width: 100%;

    @media (max-width: 1023px) {
      max-width: none;
    }
  }

  &__list-history {
    display: flex;
    flex-direction: column;
    gap: $space-m;
  }

  &__empty-list {
    @include body-1;

    color: $dark-primary;
  }

  &__empty-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 72px;
  }
}
</style>
