<script>
import Tag from "@/components/Tag.vue";
import { STATUS_OPERATIONS } from "@/package/const/service-tsp";
import formatDateTimezone from "@/package/helpers/format-date-timezone";

export default {
  name: "CardHistoryService",
  components: { Tag },

  props: {
    data: {
      type: Object,
      required: true,
    },

    typeCard: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  watch: {},

  computed: {
    statusOperation() {
      return STATUS_OPERATIONS[this.data.status.text];
    },

    formattedDate() {
      return formatDateTimezone(
        this.data.status.created_at
      ).toFormat("d MMMM, HH:mm", { locale: "ru" });
    },

    isShowCircleStatus() {
      if (this.typeCard === "received") {
        return !this.data.status.is_viewed_by_distributor;
      }

      return !this.data.status.is_viewed_by_consultant;
    },

    propertyHuman() {
      if (this.typeCard === "received") {
        return this.data.consultant;
      }

      return this.data.distributor;
    },

    imageUser() {
      if (this.propertyHuman.avatar) return this.propertyHuman.avatar;

      return require("../../assets/images/user_no_img.svg");
    },
  },

  methods: {
    transformNumberDischarge(number) {
      return number.toLocaleString();
    },
  },
};
</script>

<template>
  <article class="card-history-service">
    <div class="card-history-service__content content">
      <div class="content__header">
        <div v-if="isShowCircleStatus" class="content__circle" />
        <time :datetime="data.status.created_at">{{ formattedDate }}</time>

        <Tag
          :title="statusOperation.text"
          :color-inline="statusOperation.color"
        />
      </div>

      <div class="content__price-box">
        <h4>{{ data.service.name }}</h4>

        <span>{{ transformNumberDischarge(data.price) }} RC</span>
      </div>
    </div>

    <div class="card-history-service__line"></div>

    <div class="card-history-service__profile profile">
      <div class="profile__titles">
        <span>Дистрибьютор</span>
        <span>{{ propertyHuman.name }}</span>
      </div>

      <img
        class="profile__img"
        :src="imageUser"
        alt="Изображение пользователя"
      />
    </div>

    <router-link
      :to="{
        name: 'HistoryCurrentService',
        params: { id: data.id },
        query: this.$route.query,
      }"
    />
  </article>
</template>

<style lang="scss" scoped>
.card-history-service {
  display: flex;
  flex-direction: column;
  gap: $space-m;
  background: $background-white;
  border-radius: 12px;
  padding: $space-l;
  color: $dark-primary;
  position: relative;

  &__line {
    height: 0.5px;
    width: 100%;
    background: $outline-light;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $space-s;
  }

  &__profile {
    display: flex;
    gap: 8px;
  }

  > a {
    opacity: 0;
    position: absolute;
    inset: 0;
  }
}

.content {
  &__header {
    display: flex;
    align-items: center;
    gap: $space-s;

    > time {
      @include text-2;

      color: inherit;
      flex-grow: 1;
    }

    & > ::v-deep {
      .tag {
        padding: 0 $space-s;
      }
    }
  }

  &__price-box {
    > h4 {
      @include body-1-bold;

      color: inherit;
      margin-bottom: $space-xs;
      word-break: break-word;
    }

    > span {
      @include text-2;

      color: $dark-third;
    }
  }

  &__circle {
    height: 8px;
    aspect-ratio: 1;
    background: $red;
    border-radius: 50%;
  }
}

.profile {
  &__titles {
    display: flex;
    flex-direction: column;
    gap: $space-xs;
    flex-grow: 1;

    > span {
      &:first-child {
        @include caption-1;

        color: $dark-fifth;
      }

      &:last-child {
        @include text-2;

        color: inherit;
      }
    }
  }

  &__img {
    height: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
  }
}
</style>
